import FullTable from 'js/components/full-table/index.js'
import {openModalConfirmRemove} from 'js/services/modals.js'
import {mapGetters} from 'vuex'

import template from './_table.pug'

export default Vue.extend({
  template: template(),
  components: {
    FullTable
  },
  props: {
    data: {
      type: Array,
      required: true
    },
    meta: {
      type: Object,
      required: true
    }
  },
  data() {
    return {
      ready: false,
      googleAds: {
        code: null,
        scope: null,
        token: null
      },
      filters: {
        campaignSiteId: '',
        customerGoogleAds: '',
        convertionAction: '',
        convertionEvent: ''
      },
      errors: {},
      lastQueryOptions: null, // guarda los últimos filtros aplicados,
      campaigns: [],
      googleAdsAccounts: [],
      convertionActions: []
    }
  },
  computed: {
    ...mapGetters(['conversionEvents']),
    defaultOrder() {
      return {currentField: 'pageName', currentDir: 'asc'}
    },
    isAuthenticated() {
      return !!this.googleAds.token
    },
    buttomParams() {
      if (this.isAuthenticated) {
        return {class: 'active', icon: 'close', type: 'negative'}
      }
      return {class: '', icon: '', type: 'primary'}
    },
    customerGoogleAdsOptions() {
      return this.googleAdsAccounts.map(account => ({
        label: account.name,
        value: account.googleCustomerId
      }))
    },
    convertionActionOptions() {
      return this.convertionActions.map(action => ({
        label: action.name,
        value: action.id
      }))
    },
    campaignsOptions() {
      return this.campaigns.map(campaign => ({
        label: campaign.name,
        value: campaign.id
      }))
    },
    conversionEventsOptions() {
      return this.conversionEvents.map(event => ({
        label: event.name,
        value: event.key
      }))
    }
  },

  created() {
    this.handlerAuthStatus()
    this.loadCampaigns()
    this.ready = true
  },

  methods: {
    loadAfterAuth() {
      this.loadGoogleAdsAccounts()
    },
    loadCampaigns() {
      API.customers.campaigns.sites(this.$route.params.id).then(response => {
          this.campaigns = response.data
      })
    },
    loadGoogleAdsAccounts() {
      API.adwords.accountsGoogleAds(this.$route.params.id).then(response => {
        this.googleAdsAccounts = response.data
      })
    },
    loadActionConversion(event) {
      this.$refs.table.filterData.convertionAction = ''
      this.convertionActions = []
      if (!event) return
      const googleCustomerId = event
      API.adwords.actionConversion(googleCustomerId,this.$route.params.id).then(response => {
        this.convertionActions = response.data
      })
    },
    checkFilters(filter) {
      // Asignamos los filtros a un objeto para poder iterar sobre ellos
      const objectFilters = filter ? {...this.filters, ...filter} : this.filters
      this.errors = {}
      const error = {}
      Object.entries(objectFilters).forEach(([key, value]) => {
        if (!value) {
          error[key] = this.$t('errors.blank')
        }
      })
      this.errors = error
    },
    oauthSignIn() {
      const oauth2Endpoint = 'https://accounts.google.com/o/oauth2/v2/auth'
      const params = new URLSearchParams({
        client_id: VUE_GOOGLE_ADS_CLIENT_ID,
        redirect_uri: `${window.location.origin}/google-ads/auth`,
        response_type: 'code',
        scope: 'https://www.googleapis.com/auth/adwords',
        access_type: 'offline',
        include_granted_scopes: 'true',
        prompt: 'consent',
        state: this.$route.params.id
      })
      // Redirect to the OAuth 2.0 endpoint another tab.
      window.open(`${oauth2Endpoint}?${params.toString()}`, '_blank')
    },

    async handlerAuthStatus() {
      const query = this.$route.query
      if (query?.scope && query?.code) {
        this.googleAds.code = query.code
        this.googleAds.scope = query.scope
        this.saveToken()
      } else {
        this.checkToken()
      }
    },

    checkToken() {
      API.adwords.getToken(this.$route.params.id).then(({data}) => {
        this.googleAds.token = data.refreshToken
        this.loadAfterAuth()
      })
    },

    saveToken() {
      const payload = {
        code: this.googleAds.code
      }
      API.adwords.setToken(this.$route.params.id, payload).then(({data}) => {
        this.$notifications.success(this.t('succesfullyAuth'))
        this.googleAds.token = data.refreshToken
        this.loadAfterAuth()
      })
    },

    getPayload(filter) {
      const customerGoogleAds = this.customerGoogleAdsOptions
        .find(({value}) => value === filter.customerGoogleAds)
      const convertionAction = this.convertionActionOptions
        .find(({value}) => value === filter.convertionAction)
      const convertionEvent = this.conversionEventsOptions
        .find(({value}) => value === filter.convertionEvent)
      return {
        customerId: this.$route.params.id,
        campaignSiteId: filter.campaignSiteId,
        customerGoogleAds: {
          id: customerGoogleAds.value,
          name: customerGoogleAds.label
        },
        convertionAction: {
          id: convertionAction.value,
          name: convertionAction.label
        },
        convertionEvent: {
          key: convertionEvent.value,
          name: convertionEvent.label
        }
      }
    },

    applyFilters(queryOptions) {
      this.checkFilters(queryOptions.filter)
      if (!Object.keys(this.errors).length) {
        const payload = this.getPayload(queryOptions.filter)
        API.adwords.create(payload).then(() => {
          this.getData()
          this.$notifications.success(this.t('succesfullyCreated'))
        })
      }
    },

    openConfirmModal(row) {
      openModalConfirmRemove(
        this.t('confirmationModal.remove.title'),
        this.t('confirmationModal.remove.body'),
        this.t('confirmationModal.remove.okButton')
      ).then(() => this.destroy(row))
    },

    openConfirmModalEnable(row) {
      openModalConfirmRemove(
        this.t('confirmationModal.enable.title'),
        this.t('confirmationModal.enable.body', {name: row.name}),
        this.t('confirmationModal.enable.okButton')
      ).then(() => this.enable(row))
    },

    openConfirmModalDisable(row) {
      openModalConfirmRemove(
        this.t('confirmationModal.disable.title'),
        this.t('confirmationModal.disable.body', {name: row.name}),
        this.t('confirmationModal.disable.okButton')
      ).then(() => this.disable(row))
    },

    t(key, options = {}) {
      return this.$t(`customers.adwords.${key}`, options)
    },

    destroy(object) {
      API.adwords.destroy(object.id).then(() => {
        this.getData()
        this.$notifications.success(this.t('succesfullyDeleted'))
      })
    },

    enable(object) {
      API.adwords.enable(object.id).then(() => {
        this.getData()
        this.$notifications.success(this.t('succesfullyEnabled'))
      })
    },

    disable(object) {
      API.adwords.disable(object.id).then(() => {
        this.getData()
        this.$notifications.success(this.t('succesfullyDisabled'))
      })
    },

    getData(queryOptions) {
      // Vaciamos los filtros, por que no se usan en este endpoint
      if (queryOptions) {
        queryOptions.filter = {}
      }
      this.$emit('load-data', queryOptions || this.lastQueryOptions)

      if (queryOptions) this.lastQueryOptions = queryOptions
    }
  }
})
